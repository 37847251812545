import "./style.css";
import HeroImg from "../../Assets/Home/hero.png";
import { Button } from "../../Components";
import { useNavigate } from "react-router-dom";

const Hero = () => {
    const navigate = useNavigate();
    return (
        <div className="main-hero-div">
            <div className="hero-hd">
                <h1>
                    Crafted for the Bold,
                    <br className="width-576-less" />
                    Worn
                    <br className="width-576-greater" />
                    by the Timeless.
                </h1>
                <Button title="Shop now" onClick={e => navigate('/collection/new_arrival')} />
            </div>
            <img src={HeroImg} alt="img-1" />
        </div>
    );
};

export default Hero;